::-webkit-scrollbar {
  /*display: none;*/
}

#chat-modal,
#notes-modal,
#log-modal {
  /* modal too low */
  /* margin-top: 10%; */
  text-align: center;
}

#chat-incident-info {
  height: 600px;
  background-color: #faf8f8;
  padding-bottom: 0;
}

#chat-modal-body,
#notes-modal-body,
#log-modal-body {
  padding: 0;
}

#chat-main.row {
  margin-left: -10px;
}

#chat-messages {
  padding-right: 0;
  padding-left: 0;

  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.chat-thread {
  height: 400px;
  overflow: auto;
  padding-bottom: 50px;
  margin-bottom: 72px;
}

.chat-thread:after {
  content: '';
  display: block;
}

#chat-input,
.page-wrap:after {
  height: 72px;
}

#chat-input {
  margin-bottom: 0;
  position: fixed;
  width: 604px;
  bottom: 0;
}
/*  xt put in textarea */
.textArea { 
  background: #ccc !important; 
  font-size:12px !important; 
 }

#chat-input-box {
  padding: 0 12px;
  height: 72px;
  border-top-color: #efeaea;
  border-top-width: 2px;
  border-right-color: #efeaea;
  border-right-width: 2px;
  border-left: none;
  border-bottom: none;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: none;
}

#chat-input-box:focus {
  outline: 0;
  box-shadow: none;
}

#chat-send-button {
  height: 72px;
  vertical-align: top;
  margin-top: 0;
  float: right;
  border-top-color: #efeaea;
  border-top-width: 2px;
  border-right: none;
  border-left: none;
  border-bottom: none;
  border-radius: 0;
  border-bottom-right-radius: 5px;
  color: #3f93cd;
}

.end-chat {
  padding-top: 15px;
  width: 80%;
  position: absolute;
  /* right: 10%; */
  left: 5%; /* need red button to align with blue button */
  bottom: 15px;
}

.incident-gutter {
  margin-top: 20%;
  margin-bottom: 30%;
  /* margin-left: 11%;  added to move blue button to the left-wk inspector not wk in code  it pushes drop btn under red btn*/
  text-align: left;
}

.spacer {
  height: 25px;
}
/* xt added to adjust chat box left side spacing  */
.spacer20 {
  height: 20px;
}
.chat-meta {
  padding-top: 5px;
}

.chat-meta span {
  margin-right: 5px;
}

.chat-thread .list-group-item {
  border: none;
  color: #c1c6cf;
}

.chat-thread #reporter-message,
.chat-thread #responder-message {
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 3px;
  font-size: 16px;
  color: #000000;
}
/* these were backwards..xt fixing to put on proper sides of chat modal */
.responder-item {
  float: right;
  width: 60%;
}

.reporter-item {
  float: left;
  margin-left: 10px;
  width: 60%;
}

#responder-message {
  background-color: #f7eec2;
}

#reporter-message {
  background-color: #cbe2fe;
}

#chat-incident-info {
  background-color: #faf8f8;
  padding-bottom: 0;
}


/* dropdown items  font refers to text line color*/

a.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #292727;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-decoration: none;
}

/* xt thought this would color the button text but not.. */
#dropdown-basic {
  color: red;
  background-color:blue;
}

/* Dropup Button */
.my-dropbtn {
  background-color: #2d54c1;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropup content changed 100px to 80px to nudge up didnt */
.my-dropup {
  position: relative;
  display: inline-block;
  margin-top: 50px;
}

/* Dropup content (Hidden by Default) */
.my-dropup-content {
  display: none;
  position: absolute;
  bottom: 50px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  height: 300px;
  overflow: auto;
}

/* Links inside the dropup changed padd top from 12 to 5*/
.my-dropup-content a {
  color: black;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropup links on hover */
.my-dropup-content a:hover {background-color: #ddd}

/* Show the dropup menu on hover */
.my-dropup:hover .my-dropup-content {
  display: block;
}

/* Change the background color of the dropup button when the dropup content is shown */
.my-dropup:hover .my-dropbtn {
  background-color: rgb(125, 141, 236);
}

@media only screen and (max-width: 1200px) {
  #chat-modal,
  #notes-modal,
  #log-modal {
    margin-top: 2%;
  }

  #chat-input {
    width: 654px;
  }
}

@media only screen and (max-width: 1000px) {
  #chat-modal,
  #notes-modal,
  #log-modal {
    margin-top: 1%;
  }

  #chat-incident-info {
    height: 600px;
    width: 30%;
  }

  .chat-thread {
    height: 200px;
  }

  #chat-input {
    width: 371px;
  }
}

/* ADD Style Hacks for IE 11 Modal input messages */
@media screen and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  #chat-input {
    position:relative;
    width: 100%;
  }

  .input-group-btn, .input-group-btn > btn {
    width: 9.2%
  }
}

@media all and (min-width:1200px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
 #chat-messages {
   width: 40%;
 }
}

@media all and (max-width:1000px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  .chat-thread {
    height: 400px;
    margin-bottom: 20px;
  }

  #chat-input {
    width: 100%;
  }
}

@media all and (max-width:1200px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  #chat-input {
    width: 100%;
  }
}
/* END IE Style hacks */

@import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

#image-header {
  border-bottom: 0;
}

.image-gallery-custom-controls {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    left: 15px;
    bottom: 15px;
}

#image-gallery-share-image, #image-gallery-rotate-image {
  color: #fff;
  padding-right: 10px;
  text-shadow: 0 1px 1px #1a1a1a;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
}

#image-gallery-share-image a:hover, #image-gallery-share-image a:focus {
  color: #fff;
  text-decoration: none;
}

#image-gallery-share-image i, #image-gallery-rotate-image i  {
  font-size: 2.7em;
}

#department-modal > div, #role-modal > div, #unit-modal > div, #shift-modal > div {
  width: 30%;
  margin-top: 20%;
  text-align: center;
}

#start-date-modal > div {
  width: 30%;
  margin-top: 10%;
  text-align: center;
  font-size: 2em;
}

#map-modal > div {
  width: 70%;
  margin-top: 20%;
  text-align: center;
}



.modal-header {
  background-color: #24262D;
  color: #ffffff;
}

#map-modal .modal-body {
  padding-right: 0;
  padding-left: 5px;
}

#department-modal .modal-body,
#unit-modal .modal-body,
#shift-modal .modal-body,
#role-modal .modal-body,
#start-date-modal .modal-body {
  padding-right: 0;
  padding-left: 0;
  margin: 0 auto;
  overflow: hidden;
}

#resource-modal .modal-body {
  padding-left: 0;
  padding-right: 0;
}

#department-modal .list-group,
#role-modal .list-group,
#unit-modal .list-group,
#shift-modal .list-group {
  text-align: left;
  width: 100%;
  max-height: 400px;
  overflow: auto;
}

#chat-modal .list-group,
#notes-modal .list-group,
#log-modal .list-group,
#resource-modal .list-group {
  text-align: left;
  width: 100%;
}

#department-modal .list-group-item,
#role-modal .list-group-item,
#unit-modal .list-group-item,
#resource-modal .list-group-item,
#shift-modal .list-group-item {
    border-left: none;
    border-right: none;
}

#department-search-input, #unit-search-input, #role-search-input, #shift-search-input {
  width: 90%;
  margin: auto;
}

#department-search, #role-search, #unit-search, #shift-search {
  background-color: #F7F6F7;
}

#map-modal .close,
#department-modal .close,
#role-modal .close,
#unit-modal .close,
#chat-modal .close,
#notes-modal .close,
#log-modal .close,
#resource-modal .close,
#shift-modal .close,
#start-date-modal .close {
  color: #ffffff;
  font-size: 24px;
  text-shadow: none;
}

#map-modal .modal-content
#department-modal .modal-content,
#role-modal .modal-content,
#unit-modal .modal-content,
#chat-modal .modal-content,
#notes-modal .modal-content,
#log-modal .modal-content,
#resource-modal .modal-content,
#shift-modal .modal-content,
#start-date-modal .modal-content {
  box-shadow: 0 5px 15px rgba(25, 25, 25, 0.5);
  border: none;
}

.department-name, .shift-name, .unit-name, .role-name {
  display: inline-block;
  margin-left: 12px;
  vertical-align: middle;
}

svg {
  display: inline-block;
   vertical-align: middle;
}

img {
  cursor: pointer;
}

@media only screen and (max-height: 1000px) {
  #department-modal > div,
  #role-modal > div,
  #unit-modal > div,
  #shift-modal > div {
    width: 50%;
    margin-top: 40px;
    text-align: center;
  }

  #map-modal > div {
    width: 70%;
    margin-top: 40px;
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) {
  #department-modal > div,
  #role-modal > div,
  #unit-modal > div,

  #shift-modal > div {
    width: 50%;
    margin-top: 7%;
    text-align: center;
  }

  #start-date-modal > div {
    margin-top: 40px;
  }

  #map-modal > div {
    width: 70%;
    margin-top: 7%;
    text-align: center;
  }
}

@media all and (min-width:768px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  .modal-dialog {
    width: 768px;
  }
}

@media only screen and (max-width: 1000px) {
  #chat-modal, #notes-modal, #log-modal  {
    width: 90%;
    margin-top: 1%;
  }
}

.time {
  color: #6f747a;
}

.time div, .time span {
  margin-right: 5px;
}

.fix-display{
	display: -webkit-box;
}

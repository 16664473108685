@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.main-logo {
  background-color: #354052
}

#top-nav {
  background-color: #354052;
}

.selected-Tab {
  background-color: #FFD507
}

#top-nav .navbar-nav > li > a {
    color: #bbb;
    padding-bottom: 10px;
    border-bottom: 1px;
}

#top-nav .navbar-nav > .active > a, #top-nav .navbar-nav > .active > a:hover, #top-nav .navbar-nav > .active > a:focus {
    background-color:  #2F3949;
    color: #eee;
    border-bottom: 12px solid #FFD507;
}

#top-nav .navbar-nav > .open > a, #top-nav .navbar-nav > .open > a:hover, #top-nav .navbar-nav > .open > a:focus {
    background-color: #2F3949;
}

#logout-dropdown {
  vertical-align: middle;
  line-height: 27px;
  padding-left: 0px;
}

.profilePicture {
  width: 32px;
  border: 2px solid #ffffff;  
  border-radius: 100%;
}

#help-icon {
  vertical-align: middle;
  line-height: 27px;
  padding-left: 0px;
}

.navbar-header {
  height: 57px;
  position: relative;
  width: 46px;
}
.navbar-header img.header-image {
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}
#login-background img {
  width: 100%;
  margin: 0 auto;
  margin-top: -20px;
}

body {
  background-color: #F9F9FA !important;
}


.showbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5%;
  height: 70%;
}

.loader {
  position: relative;
  margin: 0 auto;
  width: 100px;
}
.loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@-webkit-keyframes color {
  100%,
  0% {
    stroke: rgb(53, 64, 82);
  }
  40% {
    stroke: rgb(53, 64, 82);
  }
  66% {
    stroke: rgb(53, 64, 82);
  }
  80%,
  90% {
    stroke: rgb(53, 64, 82);
  }
}
@keyframes color {
  100%,
  0% {
    stroke: rgb(53, 64, 82);
  }
  40% {
    stroke: rgb(53, 64, 82);
  }
  66% {
    stroke: rgb(53, 64, 82);
  }
  80%,
  90% {
    stroke: rgb(53, 64, 82);
  }
}

body {
  margin: 0;
}

h1 {
  text-align: center;
}

tr div {
  clear: both; 
  float: left; 
  display: block; 
  position: relative;
}

.summary-display {
  color: #F13834;
  font-weight: bold;
}

.summary-title {
  font-weight: bold;
  padding-top: 2%;
  padding-bottom: 2%;
}

.description, .location {
  padding-top: 5%;
  vertical-align: middle;
  line-height: 24px;
}

.description-column, .location-column {
  width: 17%;
}

.incident-column {
  width: 14%
}

.reporter-column {
  width: 15%;
}

.reporter-column, .responder-column, .description-container {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.description-container{
  white-space: normal;
}

.image-text {
  vertical-align: middle;
  margin-left: 6px;
}

.material-icons {
  vertical-align: middle;
}

.btn {
  margin-top: 5%;
}

a:hover {
  cursor: pointer;
}

a:focus {
  outline: none;
}

@media only screen and (max-width: 1200px) {
  .time span {
    display: inline-block;
  }
}

@media only screen and (max-width: 1000px) {
  .summary-display {
    font-size: 16px !important;
  }
}

.incident-location{
  white-space: normal;
}
.rt-td{
  padding: 8px !important;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd
}

rt-td div{
  clear: both;
  float: left;
  display: block;
  position: relative;
}
.rt-td.action-column, .rt-th.rt-resizable-header.-cursor-pointer:last-child {
  width: 15% !important;
}

.pagination-main-block{
  width: 100%;
  /* background-color: #F13834; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  

}
.display-flex-common{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

}
.previous-div{
  width: 29%;
  font-size: 15px;
  background-color: rgba(0,0,0,0.1);
}
/* .previous{

} */
.totalPage-div{
  width: 26%;
  
}
.rows-div{
  width: 16%;
  
}
.next-div{
  width: 29%;
  font-size: 15px;
  background-color: rgba(0,0,0,0.1);

}
.time {
  color: #6f747a;
}

.time div, .time span {
  margin-right: 5px;
}

.fix-display{
	display: -webkit-box;
}

.responder-container {
  clear: none;
  width: 18%;
}

.responder-tag, .responder-dept {
  clear: none;
  float: left;
  margin-top: 5px;
  min-height: 60px;
}

.responder-tag div, .responder-dept div {
  float: none;
}

#incident-tag {
  margin-top: 3px;
  width: 90%;
  text-align: center;
  height: 24px;
  z-index: 0;
}

input.required::-webkit-input-placeholder, button.required {
  color: #d9534f;
}

input.required::-ms-input-placeholder, button.required {
  color: #d9534f;
}

input.required::placeholder, button.required {
  color: #d9534f;
}

.responder-dept {
  width: 59%;
  margin-right: 6px;
}

.responder-tag {
  width: 37%;
}

.responder-tag button {
  margin-bottom: 5px;
}

#delete-button {
  margin-left: 1px;
  display: inline-block;
  position: absolute;
}

#dept-button {
  padding: 2px 4px; 
}

#unit-button {
  padding: 2px 4px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.unit-title {
  margin-top: 8px;
}
.rt-td.responder-column {
  white-space: pre-wrap;
}
.rt-td{
  white-space: normal;
}
.fix-whitespace{
  white-space: normal; 
}
div.state-circle {
  padding: 10px;
  border-radius: 100%;
  margin-left: 30px;
  margin-top: 6px;
  margin-right: 5px;
  vertical-align: middle;
  width: 1%;
  display: inline-block;
  position: absolute;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

@media only screen and (max-width: 1000px) {
  .responder-tag {
    width: 100%;
  }

  .responder-container {
    clear: none;
    width: 22%;
  }

  .responder-tag input {
    width: 60%;
  }
}
.status-container {
  clear: none;
  width: 100%;
  margin: 0 auto;
}

.action-container {
  clear: both;
  width: 100%;
  /*max-width: 226px;*/
  position: relative;
  -webkit-hyphens: 80px;
      -ms-hyphens: 80px;
          hyphens: 80px;

}

.action-status, .action-chat, .action-note, .action-log, .action-resource  {
  clear: none;
  /*float: left;*/
  float:none;
  display: inline-table;
  min-height: 70px;
}

.action-status .dropdown {
  margin-right: 15px;
}

.btn .caret {
  margin-right: 15px;
}

.action-status div {
  margin-left: 8px;
  margin-right: 8px;
}

.action-chat div, .action-note div, .action-resource div, .action-log div {
  clear: none;
  float: none;
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: Helvetica Neue;
}

.dropdown button {
  height: 35px;
  border-radius: 25px;
}

.status-dropdown div, div.status-dropdown {
  clear: both;
  float: left;
  color: #B3B5B7;
}

#state-button {
  padding: 1px 4px;
}

#state-button > li > a, .caret {
  color: #B3B5B7;
}

.action-status {
  width: 80%;
}

.action-chat, .action-note, .action-log, .action-resource {
  width: 20%;
  min-width: 38px;
  text-align: -webkit-auto;
  position: relative;
}

.action-resource > div {
  margin-left: -3px;
}

#chat-button, #note-button, #log-button, #resource-button, #delete-button.del-action {
  text-align: center;
  border-radius: 100%;
  color: #B3B5B7;
  border-color: #B3B5B7;
  padding: 1px 4px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 40px;
}

#chat-icon, #note-icon, #resource-icon, #log-icon, #delete-icon {
  color: #B3B5B7;
  padding: 3px;
}

#chat-icon:focus, #note-icon:focus, #resource-icon:focus, #log-icon:focus, #delete-icon:focus {
  outline: none;
}

#chat-button.user-recent {
  background-color: #D03B3B;
}

#chat-button.dashboard-recent {
  background-color: #1F9B0D;
}

#chat-button.chat-closed, #log-button.log-received, #note-button.note-received {
  background-color: #2563AB;
}


#chat-button.user-recent #chat-icon,
#chat-button.dashboard-recent #chat-icon,
#chat-button.chat-closed #chat-icon,
#log-button.log-received #log-icon,
#note-button.note-received #note-icon {
  color: #ffffff;
}

div.status-circle {
  padding: 8px;
  border-radius: 100%;
  margin-left: auto;
  margin-top: 2px;
  margin-right: 5px;
  vertical-align: middle;
  width: 1%;
}

@media only screen and (max-width: 1200px) {
  .action-note, .action-resource, .action-chat, .action-log {
    /*clear: both;*/
    float: left;
    min-height: 65px;
    display: inline-table;
  }

  .action-chat div, .action-note div, .action-log div, .action-resource div {
    clear: both;
    float: none;
  }
}

@media only screen and (max-width: 1000px) {
  .action-chat, .action-log, .action-note, .action-resource {
    /*clear: both;*/
    /*margin-left: 8px;*/
    /*float: none;*/
    float: left;
    min-height: 60px;
    display: inline-table;
  }

  .action-status {
    /*width: 15%;*/
  }
  .rt-td.action-column, .rt-th.rt-resizable-header.-cursor-pointer:last-child {
    width: 20% !important;
  }
}

#delete-button{
  margin-top: 3px;
}
.react-datepicker {
  font-size: 0.75em;
}
.react-datepicker__header {
  padding-top: 0.6em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}
::-webkit-scrollbar {
  /*display: none;*/
}

#chat-modal,
#notes-modal,
#log-modal {
  /* modal too low */
  /* margin-top: 10%; */
  text-align: center;
}

#chat-incident-info {
  height: 600px;
  background-color: #faf8f8;
  padding-bottom: 0;
}

#chat-modal-body,
#notes-modal-body,
#log-modal-body {
  padding: 0;
}

#chat-main.row {
  margin-left: -10px;
}

#chat-messages {
  padding-right: 0;
  padding-left: 0;

  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.chat-thread {
  height: 400px;
  overflow: auto;
  padding-bottom: 50px;
  margin-bottom: 72px;
}

.chat-thread:after {
  content: '';
  display: block;
}

#chat-input,
.page-wrap:after {
  height: 72px;
}

#chat-input {
  margin-bottom: 0;
  position: fixed;
  width: 604px;
  bottom: 0;
}
/*  xt put in textarea */
.textArea { 
  background: #ccc !important; 
  font-size:12px !important; 
 }

#chat-input-box {
  padding: 0 12px;
  height: 72px;
  border-top-color: #efeaea;
  border-top-width: 2px;
  border-right-color: #efeaea;
  border-right-width: 2px;
  border-left: none;
  border-bottom: none;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: none;
}

#chat-input-box:focus {
  outline: 0;
  box-shadow: none;
}

#chat-send-button {
  height: 72px;
  vertical-align: top;
  margin-top: 0;
  float: right;
  border-top-color: #efeaea;
  border-top-width: 2px;
  border-right: none;
  border-left: none;
  border-bottom: none;
  border-radius: 0;
  border-bottom-right-radius: 5px;
  color: #3f93cd;
}

.end-chat {
  padding-top: 15px;
  width: 80%;
  position: absolute;
  /* right: 10%; */
  left: 5%; /* need red button to align with blue button */
  bottom: 15px;
}

.incident-gutter {
  margin-top: 20%;
  margin-bottom: 30%;
  /* margin-left: 11%;  added to move blue button to the left-wk inspector not wk in code  it pushes drop btn under red btn*/
  text-align: left;
}

.spacer {
  height: 25px;
}
/* xt added to adjust chat box left side spacing  */
.spacer20 {
  height: 20px;
}
.chat-meta {
  padding-top: 5px;
}

.chat-meta span {
  margin-right: 5px;
}

.chat-thread .list-group-item {
  border: none;
  color: #c1c6cf;
}

.chat-thread #reporter-message,
.chat-thread #responder-message {
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 3px;
  font-size: 16px;
  color: #000000;
}
/* these were backwards..xt fixing to put on proper sides of chat modal */
.responder-item {
  float: right;
  width: 60%;
}

.reporter-item {
  float: left;
  margin-left: 10px;
  width: 60%;
}

#responder-message {
  background-color: #f7eec2;
}

#reporter-message {
  background-color: #cbe2fe;
}

#chat-incident-info {
  background-color: #faf8f8;
  padding-bottom: 0;
}


/* dropdown items  font refers to text line color*/

a.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #292727;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-decoration: none;
}

/* xt thought this would color the button text but not.. */
#dropdown-basic {
  color: red;
  background-color:blue;
}

/* Dropup Button */
.my-dropbtn {
  background-color: #2d54c1;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropup content changed 100px to 80px to nudge up didnt */
.my-dropup {
  position: relative;
  display: inline-block;
  margin-top: 50px;
}

/* Dropup content (Hidden by Default) */
.my-dropup-content {
  display: none;
  position: absolute;
  bottom: 50px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  height: 300px;
  overflow: auto;
}

/* Links inside the dropup changed padd top from 12 to 5*/
.my-dropup-content a {
  color: black;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropup links on hover */
.my-dropup-content a:hover {background-color: #ddd}

/* Show the dropup menu on hover */
.my-dropup:hover .my-dropup-content {
  display: block;
}

/* Change the background color of the dropup button when the dropup content is shown */
.my-dropup:hover .my-dropbtn {
  background-color: rgb(125, 141, 236);
}

@media only screen and (max-width: 1200px) {
  #chat-modal,
  #notes-modal,
  #log-modal {
    margin-top: 2%;
  }

  #chat-input {
    width: 654px;
  }
}

@media only screen and (max-width: 1000px) {
  #chat-modal,
  #notes-modal,
  #log-modal {
    margin-top: 1%;
  }

  #chat-incident-info {
    height: 600px;
    width: 30%;
  }

  .chat-thread {
    height: 200px;
  }

  #chat-input {
    width: 371px;
  }
}

/* ADD Style Hacks for IE 11 Modal input messages */
@media screen and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  #chat-input {
    position:relative;
    width: 100%;
  }

  .input-group-btn, .input-group-btn > btn {
    width: 9.2%
  }
}

@media all and (min-width:1200px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
 #chat-messages {
   width: 40%;
 }
}

@media all and (max-width:1000px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  .chat-thread {
    height: 400px;
    margin-bottom: 20px;
  }

  #chat-input {
    width: 100%;
  }
}

@media all and (max-width:1200px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  #chat-input {
    width: 100%;
  }
}
/* END IE Style hacks */

#image-header {
  border-bottom: 0;
}

.image-gallery-custom-controls {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    left: 15px;
    bottom: 15px;
}

#image-gallery-share-image, #image-gallery-rotate-image {
  color: #fff;
  padding-right: 10px;
  text-shadow: 0 1px 1px #1a1a1a;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
}

#image-gallery-share-image a:hover, #image-gallery-share-image a:focus {
  color: #fff;
  text-decoration: none;
}

#image-gallery-share-image i, #image-gallery-rotate-image i  {
  font-size: 2.7em;
}

#map-modal-body > div {
  position: static !important;
  width: 100% !important;
  height: 600px !important;
}
::-webkit-scrollbar {
  /*display: none;*/
}

#resource-modal > div {
  width: 100%;
  margin-top: 40%;
  text-align: center;
}
#edits-received {
  color: "#ff0000"
}
#resource-modal-body {
  height: 80%;
  padding-right: 0;
  padding-left: 0;
  margin: 0 auto;
  overflow: hidden;
}

#map {
  position:fixed;
  height: 400px;
  top: 57px;
  width: 100%;
}

#map > div {
  position: static !important;
  width: 100% !important;
  height: 20% !important;
}

#resource-modal-body .list-group {
  position: fixed;
  height: 300px;
  top: 425px;
  overflow: auto;
}

.resource-list .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.resource-display {
  vertical-align: middle;
  margin-left: 5px;
  display: inline-block;
}

.history-times span {
  margin-right: 5px;
}

.elapsed-time {
  float: right;
  vertical-align: middle;
  line-height: 40px;
}

@media only screen and (max-width: 1200px) {
  #resource-modal > div {
    width: 60%;
    margin-top: 7%;
    text-align: center;
  }

  #map {
    position:fixed;
    height: 250px;
    top: 57px;
    width: 100%;
  }

  #map > div {
    position: static !important;
    width: 100% !important;
    height: 20% !important;
  }

  #resource-modal-body .list-group {
    position: fixed;
    max-height: 300px;
    top: 307px;
    overflow: auto;
  }
}

@media only screen and (max-width: 1000px) {
  #resource-modal > div {
    width: 60%;
    margin-top: 2%;
  }
}

@media only screen and (max-height: 1000px) {
  #resource-modal > div {
    width: 60%;
    margin-top: 40px;
  }
}

#department-modal > div, #role-modal > div, #unit-modal > div, #shift-modal > div {
  width: 30%;
  margin-top: 20%;
  text-align: center;
}

#start-date-modal > div {
  width: 30%;
  margin-top: 10%;
  text-align: center;
  font-size: 2em;
}

#map-modal > div {
  width: 70%;
  margin-top: 20%;
  text-align: center;
}



.modal-header {
  background-color: #24262D;
  color: #ffffff;
}

#map-modal .modal-body {
  padding-right: 0;
  padding-left: 5px;
}

#department-modal .modal-body,
#unit-modal .modal-body,
#shift-modal .modal-body,
#role-modal .modal-body,
#start-date-modal .modal-body {
  padding-right: 0;
  padding-left: 0;
  margin: 0 auto;
  overflow: hidden;
}

#resource-modal .modal-body {
  padding-left: 0;
  padding-right: 0;
}

#department-modal .list-group,
#role-modal .list-group,
#unit-modal .list-group,
#shift-modal .list-group {
  text-align: left;
  width: 100%;
  max-height: 400px;
  overflow: auto;
}

#chat-modal .list-group,
#notes-modal .list-group,
#log-modal .list-group,
#resource-modal .list-group {
  text-align: left;
  width: 100%;
}

#department-modal .list-group-item,
#role-modal .list-group-item,
#unit-modal .list-group-item,
#resource-modal .list-group-item,
#shift-modal .list-group-item {
    border-left: none;
    border-right: none;
}

#department-search-input, #unit-search-input, #role-search-input, #shift-search-input {
  width: 90%;
  margin: auto;
}

#department-search, #role-search, #unit-search, #shift-search {
  background-color: #F7F6F7;
}

#map-modal .close,
#department-modal .close,
#role-modal .close,
#unit-modal .close,
#chat-modal .close,
#notes-modal .close,
#log-modal .close,
#resource-modal .close,
#shift-modal .close,
#start-date-modal .close {
  color: #ffffff;
  font-size: 24px;
  text-shadow: none;
}

#map-modal .modal-content
#department-modal .modal-content,
#role-modal .modal-content,
#unit-modal .modal-content,
#chat-modal .modal-content,
#notes-modal .modal-content,
#log-modal .modal-content,
#resource-modal .modal-content,
#shift-modal .modal-content,
#start-date-modal .modal-content {
  box-shadow: 0 5px 15px rgba(25, 25, 25, 0.5);
  border: none;
}

.department-name, .shift-name, .unit-name, .role-name {
  display: inline-block;
  margin-left: 12px;
  vertical-align: middle;
}

svg {
  display: inline-block;
   vertical-align: middle;
}

img {
  cursor: pointer;
}

@media only screen and (max-height: 1000px) {
  #department-modal > div,
  #role-modal > div,
  #unit-modal > div,
  #shift-modal > div {
    width: 50%;
    margin-top: 40px;
    text-align: center;
  }

  #map-modal > div {
    width: 70%;
    margin-top: 40px;
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) {
  #department-modal > div,
  #role-modal > div,
  #unit-modal > div,

  #shift-modal > div {
    width: 50%;
    margin-top: 7%;
    text-align: center;
  }

  #start-date-modal > div {
    margin-top: 40px;
  }

  #map-modal > div {
    width: 70%;
    margin-top: 7%;
    text-align: center;
  }
}

@media all and (min-width:768px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  .modal-dialog {
    width: 768px;
  }
}

@media only screen and (max-width: 1000px) {
  #chat-modal, #notes-modal, #log-modal  {
    width: 90%;
    margin-top: 1%;
  }
}

#update-notice {
  position: absolute; 
  z-index: 100;
  top: 58px;
  width: 90%; 
  height: 50px; 
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.fade {
  opacity: 0;
  transition: opacity 3000ms linear;
}

.in {
  opacity: 1;
}
#state_visibility_tabs a {
  font-size: 18px;
}

#responder-map-container {
  margin-top: -20px;
}

#responder-map-container > div {
    height: 87vh !important;
}
img.img-responsive {
  display: inline;
}
/* 2023 created around August: 
** LOCATION:  src/components/Reports/NewReports.css  version 3.0.2
**            xt this was created by sultan for the reports tab. 
I need to change the name back to reports.css though. I want clean readable, maintainable code
** PLEASE PUT ALL CHANGE HISTORY NOTES HERE AS WELL AS IN THE CODE LOCATION ITSELF!!!
** PLEASE USE USA TIME FORMAT  MM/DD/YYYY  OR YYYYMMDD
** 20230915: GK made changes to keep the Reports Tab table, sticky so it stays on top when scrolling.
** 20230915: XT made some changes to the scroll bar colors and sizes - bigger/darker fit BSG theme
*/

body {
  margin: 0;
}

h1 {
  text-align: center;
}
/*New code added 'table-header-wrapper' by GK 08-25-2023 to make header sticky on scrolling start*/
.table-header-wrapper{
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: #dddddd;
}
/*New code added by GK 08-25-2023 end*/
tr div {
  clear: both; 
  float: left; 
  display: block; 
  position: relative;
}

.summary-display {
  color: #F13834;
  font-weight: bold;
}

.summary-title {
  font-weight: bold;
  padding-top: 2%;
  padding-bottom: 2%;
}

.description, .location {
  padding-top: 5%;
  vertical-align: middle;
  line-height: 24px;
}

.description-column, .location-column {
  width: 17%;
}

.incident-column {
  width: 14%
}

.reporter-column {
  width: 15%;
}

.reporter-column, .responder-column, .description-container {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.description-container{
  white-space: normal;
}

.image-text {
  vertical-align: middle;
  margin-left: 6px;
}

.material-icons {
  vertical-align: middle;
}

.btn {
  margin-top: 5%;
}

a:hover {
  cursor: pointer;
}

a:focus {
  outline: none;
}

@media only screen and (max-width: 1200px) {
  .time span {
    display: inline-block;
  }
}

@media only screen and (max-width: 1000px) {
  .summary-display {
    font-size: 16px !important;
  }
}

.incident-location{
  white-space: normal;
}
.rt-td{
  padding: 8px !important;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd
}

rt-td div{
  clear: both;
  float: left;
  display: block;
  position: relative;
}
.rt-td.action-column, .rt-th.rt-resizable-header.-cursor-pointer:last-child {
  width: 15% !important;
}
button.filter-btn.btn.btn-primary {
  margin-top: 0px;
  margin-right: 10px;
}
.upper-container {
  display: inline-flex;
}
.react-bs-table .table >tbody>tr>td {
  outline: 0!important;
  white-space: normal;
  word-break: break-word;
}
.react-bs-table .table thead tr th{
  white-space: normal;
  word-break: break-word; 
}
a.btn.btn-primary.export-btn {
  margin-top: 0px;
}
.table-container {
  width: 100%;
  margin: auto;
  overflow: auto;
}
.react-bs-table.react-bs-table-bordered {
  height: 100%;
  width: 3000px;
}

/* new ui style */
.main-block-b{
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: start;
  justify-content: center;
  position: relative;
}
.sidebar{
  width: 17%;
  height:100vh;
  background-color: #0f2b3c;
  color: white;
  padding: 20px;
  overflow-y: scroll;
}
.sidebar-top-block{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding-bottom: 5px;

}
.top-filter-heading{
  font-size: 22px;
  font-weight: 600;
  color: white;

}

.sidebar-item-block{
  width: 100%;

}
.item-top-block{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.iten-header{
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
}
.iten-header-viewAll{
  font-size: 1.5rem;
  font-weight: 400;
  color: #27b74a;
  cursor: pointer;
}
.item-filter-content-b{
  width: 100%;
/* display: flex;
align-items: center;
justify-content: start; */
margin-top: 20px;
margin-bottom: 20px;
}
input[type=checkbox]{
  width: 20px;
  height: 15px;
  /* background: green; */
 
}
.label-item{
  width: 80%;
  font-size: 15px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;

}
/*  */
.center-block-div{
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
}
.center-block-div::-webkit-scrollbar {
  width: 15px; 
  height: 15px;              /* width of the entire scrollbar */
}
.center-block-div::-webkit-scrollbar-track {
  background: rgb(185, 181, 181);        /* color of the tracking area */
}
.center-block-div::-webkit-scrollbar-thumb {
  background-color: #1b3c58;    /* color of the scroll bar */
  border-radius: 1px;       /* roundness of the scroll thumb */
  border: 1px solid white;  /* creates padding around scroll bar */
}

/*  */
.sidebar::-webkit-scrollbar {
  width: 15px;               /* width of the entire scrollbar on the sidebar */
}
.sidebar::-webkit-scrollbar-track {
  background: rgb(185, 181, 181);    /* color of the tracking area  for the sidebar*/
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #1b3c58;      /* color of the scroll bar on the sidebar*/
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid white;  /* creates padding around scroll thumb */
}
.center-block-container{
  /* width: 100%; */
  /* padding: 20px; */
  /* display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column; */
  width: 100%;
  /* position: relative; */
  height: 80vh;
  position: relative;

}
.center-block-pagination-container{
  width: 100%;
  /* height: 20vh; */
  /* position: fixed; */
  /* bottom: 0px; */
  display: flex;
  align-items: center;
  justify-content: center;
}


.center-block-item{
  /* width: 100%; */
  margin-bottom: 10px;
  border: 1px solid blue;
}

.csvlink{
  width: 150px !important;
  font-size: 25px !important;
  margin-top: 0px !important;
}
.top-clearAll-heading{
  color:#f40000;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
}
.dropdown-button{
  width: 100%;
  border: none;
  border-radius: none;
}
.next-prev-div{
  width:20%;
  padding: 10px;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}
.bg-prev{
  background-color: #0f2b3c;
  color: white;

}
.bg-next{
  background-color:#337ab7 ;
  color: white;
}
.page-count-div{
  width: 20%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.input-page{
  width: 40px;
  padding: 10px;
  text-align: center;
}

.exportData-button{
  border: none;
  background-color: #337ab7;
  padding: 10px;
  color: white;
}
.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.App-content {
  flex: 1 1;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #354052;
  color: #fff;
  font-size: 12px;
  text-align: center;
  /* z-index: 999; */
}

main {
  padding-bottom: 50px;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.text-large {
  font-size: 22px
}

.text-medium {
  font-size: 18px;
}

.text-small {
  font-size: 12px;
}

.text-very-small {
  font-size: 8px;
}

#agency-list > a, #login-center-text, #error-jumbotron {
  text-align: center;
}

.text-secondary {
  color: #6c757d !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.p-1 {
  padding: .25rem
}


/* body::-webkit-scrollbar {
  width: 5px;              
}

body::-webkit-scrollbar-track {
  background: white;       
}

body::-webkit-scrollbar-thumb {
  background-color: blue;   
  border-radius: 20px;      
  border: 3px solid orange;  
} */

.responder-container {
  clear: none;
  width: 18%;
}

.responder-tag, .responder-dept {
  clear: none;
  float: left;
  margin-top: 5px;
  min-height: 60px;
}

.responder-tag div, .responder-dept div {
  float: none;
}

#incident-tag {
  margin-top: 3px;
  width: 90%;
  text-align: center;
  height: 24px;
  z-index: 0;
}

input.required::placeholder, button.required {
  color: #d9534f;
}

.responder-dept {
  width: 59%;
  margin-right: 6px;
}

.responder-tag {
  width: 37%;
}

.responder-tag button {
  margin-bottom: 5px;
}

#delete-button {
  margin-left: 1px;
  display: inline-block;
  position: absolute;
}

#dept-button {
  padding: 2px 4px; 
}

#unit-button {
  padding: 2px 4px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.unit-title {
  margin-top: 8px;
}
.rt-td.responder-column {
  white-space: pre-wrap;
}
.rt-td{
  white-space: normal;
}
.fix-whitespace{
  white-space: normal; 
}
div.state-circle {
  padding: 10px;
  border-radius: 100%;
  margin-left: 30px;
  margin-top: 6px;
  margin-right: 5px;
  vertical-align: middle;
  width: 1%;
  display: inline-block;
  position: absolute;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

@media only screen and (max-width: 1000px) {
  .responder-tag {
    width: 100%;
  }

  .responder-container {
    clear: none;
    width: 22%;
  }

  .responder-tag input {
    width: 60%;
  }
}
.main-logo {
  background-color: #354052
}

#top-nav {
  background-color: #354052;
}

.selected-Tab {
  background-color: #FFD507
}

#top-nav .navbar-nav > li > a {
    color: #bbb;
    padding-bottom: 10px;
    border-bottom: 1px;
}

#top-nav .navbar-nav > .active > a, #top-nav .navbar-nav > .active > a:hover, #top-nav .navbar-nav > .active > a:focus {
    background-color:  #2F3949;
    color: #eee;
    border-bottom: 12px solid #FFD507;
}

#top-nav .navbar-nav > .open > a, #top-nav .navbar-nav > .open > a:hover, #top-nav .navbar-nav > .open > a:focus {
    background-color: #2F3949;
}

#logout-dropdown {
  vertical-align: middle;
  line-height: 27px;
  padding-left: 0px;
}

.profilePicture {
  width: 32px;
  border: 2px solid #ffffff;  
  border-radius: 100%;
}

#help-icon {
  vertical-align: middle;
  line-height: 27px;
  padding-left: 0px;
}

.navbar-header {
  height: 57px;
  position: relative;
  width: 46px;
}
.navbar-header img.header-image {
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}
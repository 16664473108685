/* 2023 created around August: 
** LOCATION:  src/components/Reports/NewReports.css  version 3.0.2
**            xt this was created by sultan for the reports tab. 
I need to change the name back to reports.css though. I want clean readable, maintainable code
** PLEASE PUT ALL CHANGE HISTORY NOTES HERE AS WELL AS IN THE CODE LOCATION ITSELF!!!
** PLEASE USE USA TIME FORMAT  MM/DD/YYYY  OR YYYYMMDD
** 20230915: GK made changes to keep the Reports Tab table, sticky so it stays on top when scrolling.
** 20230915: XT made some changes to the scroll bar colors and sizes - bigger/darker fit BSG theme
*/

body {
  margin: 0;
}

h1 {
  text-align: center;
}
/*New code added 'table-header-wrapper' by GK 08-25-2023 to make header sticky on scrolling start*/
.table-header-wrapper{
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: #dddddd;
}
/*New code added by GK 08-25-2023 end*/
tr div {
  clear: both; 
  float: left; 
  display: block; 
  position: relative;
}

.summary-display {
  color: #F13834;
  font-weight: bold;
}

.summary-title {
  font-weight: bold;
  padding-top: 2%;
  padding-bottom: 2%;
}

.description, .location {
  padding-top: 5%;
  vertical-align: middle;
  line-height: 24px;
}

.description-column, .location-column {
  width: 17%;
}

.incident-column {
  width: 14%
}

.reporter-column {
  width: 15%;
}

.reporter-column, .responder-column, .description-container {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
.description-container{
  white-space: normal;
}

.image-text {
  vertical-align: middle;
  margin-left: 6px;
}

.material-icons {
  vertical-align: middle;
}

.btn {
  margin-top: 5%;
}

a:hover {
  cursor: pointer;
}

a:focus {
  outline: none;
}

@media only screen and (max-width: 1200px) {
  .time span {
    display: inline-block;
  }
}

@media only screen and (max-width: 1000px) {
  .summary-display {
    font-size: 16px !important;
  }
}

.incident-location{
  white-space: normal;
}
.rt-td{
  padding: 8px !important;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd
}

rt-td div{
  clear: both;
  float: left;
  display: block;
  position: relative;
}
.rt-td.action-column, .rt-th.rt-resizable-header.-cursor-pointer:last-child {
  width: 15% !important;
}
button.filter-btn.btn.btn-primary {
  margin-top: 0px;
  margin-right: 10px;
}
.upper-container {
  display: inline-flex;
}
.react-bs-table .table >tbody>tr>td {
  outline: 0!important;
  white-space: normal;
  word-break: break-word;
}
.react-bs-table .table thead tr th{
  white-space: normal;
  word-break: break-word; 
}
a.btn.btn-primary.export-btn {
  margin-top: 0px;
}
.table-container {
  width: 100%;
  margin: auto;
  overflow: auto;
}
.react-bs-table.react-bs-table-bordered {
  height: 100%;
  width: 3000px;
}

/* new ui style */
.main-block-b{
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: start;
  justify-content: center;
  position: relative;
}
.sidebar{
  width: 17%;
  height:100vh;
  background-color: #0f2b3c;
  color: white;
  padding: 20px;
  overflow-y: scroll;
}
.sidebar-top-block{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding-bottom: 5px;

}
.top-filter-heading{
  font-size: 22px;
  font-weight: 600;
  color: white;

}

.sidebar-item-block{
  width: 100%;

}
.item-top-block{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.iten-header{
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
}
.iten-header-viewAll{
  font-size: 1.5rem;
  font-weight: 400;
  color: #27b74a;
  cursor: pointer;
}
.item-filter-content-b{
  width: 100%;
/* display: flex;
align-items: center;
justify-content: start; */
margin-top: 20px;
margin-bottom: 20px;
}
input[type=checkbox]{
  width: 20px;
  height: 15px;
  /* background: green; */
 
}
.label-item{
  width: 80%;
  font-size: 15px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;

}
/*  */
.center-block-div{
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
}
.center-block-div::-webkit-scrollbar {
  width: 15px; 
  height: 15px;              /* width of the entire scrollbar */
}
.center-block-div::-webkit-scrollbar-track {
  background: rgb(185, 181, 181);        /* color of the tracking area */
}
.center-block-div::-webkit-scrollbar-thumb {
  background-color: #1b3c58;    /* color of the scroll bar */
  border-radius: 1px;       /* roundness of the scroll thumb */
  border: 1px solid white;  /* creates padding around scroll bar */
}

/*  */
.sidebar::-webkit-scrollbar {
  width: 15px;               /* width of the entire scrollbar on the sidebar */
}
.sidebar::-webkit-scrollbar-track {
  background: rgb(185, 181, 181);    /* color of the tracking area  for the sidebar*/
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #1b3c58;      /* color of the scroll bar on the sidebar*/
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid white;  /* creates padding around scroll thumb */
}
.center-block-container{
  /* width: 100%; */
  /* padding: 20px; */
  /* display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column; */
  width: 100%;
  /* position: relative; */
  height: 80vh;
  position: relative;

}
.center-block-pagination-container{
  width: 100%;
  /* height: 20vh; */
  /* position: fixed; */
  /* bottom: 0px; */
  display: flex;
  align-items: center;
  justify-content: center;
}


.center-block-item{
  /* width: 100%; */
  margin-bottom: 10px;
  border: 1px solid blue;
}

.csvlink{
  width: 150px !important;
  font-size: 25px !important;
  margin-top: 0px !important;
}
.top-clearAll-heading{
  color:#f40000;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
}
.dropdown-button{
  width: 100%;
  border: none;
  border-radius: none;
}
.next-prev-div{
  width:20%;
  padding: 10px;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}
.bg-prev{
  background-color: #0f2b3c;
  color: white;

}
.bg-next{
  background-color:#337ab7 ;
  color: white;
}
.page-count-div{
  width: 20%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.input-page{
  width: 40px;
  padding: 10px;
  text-align: center;
}

.exportData-button{
  border: none;
  background-color: #337ab7;
  padding: 10px;
  color: white;
}
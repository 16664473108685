body {
  margin: 0;
}

h1 {
  text-align: center;
}

tr div {
  clear: both; 
  float: left; 
  display: block; 
  position: relative;
}

.summary-display {
  color: #F13834;
  font-weight: bold;
}

.summary-title {
  font-weight: bold;
  padding-top: 2%;
  padding-bottom: 2%;
}

.description, .location {
  padding-top: 5%;
  vertical-align: middle;
  line-height: 24px;
}

.description-column, .location-column {
  width: 17%;
}

.incident-column {
  width: 14%
}

.reporter-column {
  width: 15%;
}

.reporter-column, .responder-column, .description-container {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
.description-container{
  white-space: normal;
}

.image-text {
  vertical-align: middle;
  margin-left: 6px;
}

.material-icons {
  vertical-align: middle;
}

.btn {
  margin-top: 5%;
}

a:hover {
  cursor: pointer;
}

a:focus {
  outline: none;
}

@media only screen and (max-width: 1200px) {
  .time span {
    display: inline-block;
  }
}

@media only screen and (max-width: 1000px) {
  .summary-display {
    font-size: 16px !important;
  }
}

.incident-location{
  white-space: normal;
}
.rt-td{
  padding: 8px !important;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd
}

rt-td div{
  clear: both;
  float: left;
  display: block;
  position: relative;
}
.rt-td.action-column, .rt-th.rt-resizable-header.-cursor-pointer:last-child {
  width: 15% !important;
}

.pagination-main-block{
  width: 100%;
  /* background-color: #F13834; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  

}
.display-flex-common{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

}
.previous-div{
  width: 29%;
  font-size: 15px;
  background-color: rgba(0,0,0,0.1);
}
/* .previous{

} */
.totalPage-div{
  width: 26%;
  
}
.rows-div{
  width: 16%;
  
}
.next-div{
  width: 29%;
  font-size: 15px;
  background-color: rgba(0,0,0,0.1);

}
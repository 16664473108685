#update-notice {
  position: absolute; 
  z-index: 100;
  top: 58px;
  width: 90%; 
  height: 50px; 
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 3000ms linear;
       -o-transition: opacity 3000ms linear;
          transition: opacity 3000ms linear;
}

.in {
  opacity: 1;
}
.status-container {
  clear: none;
  width: 100%;
  margin: 0 auto;
}

.action-container {
  clear: both;
  width: 100%;
  /*max-width: 226px;*/
  position: relative;
  hyphens: 80px;

}

.action-status, .action-chat, .action-note, .action-log, .action-resource  {
  clear: none;
  /*float: left;*/
  float:none;
  display: inline-table;
  min-height: 70px;
}

.action-status .dropdown {
  margin-right: 15px;
}

.btn .caret {
  margin-right: 15px;
}

.action-status div {
  margin-left: 8px;
  margin-right: 8px;
}

.action-chat div, .action-note div, .action-resource div, .action-log div {
  clear: none;
  float: none;
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: Helvetica Neue;
}

.dropdown button {
  height: 35px;
  border-radius: 25px;
}

.status-dropdown div, div.status-dropdown {
  clear: both;
  float: left;
  color: #B3B5B7;
}

#state-button {
  padding: 1px 4px;
}

#state-button > li > a, .caret {
  color: #B3B5B7;
}

.action-status {
  width: 80%;
}

.action-chat, .action-note, .action-log, .action-resource {
  width: 20%;
  min-width: 38px;
  text-align: -webkit-auto;
  position: relative;
}

.action-resource > div {
  margin-left: -3px;
}

#chat-button, #note-button, #log-button, #resource-button, #delete-button.del-action {
  text-align: center;
  border-radius: 100%;
  color: #B3B5B7;
  border-color: #B3B5B7;
  padding: 1px 4px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 40px;
}

#chat-icon, #note-icon, #resource-icon, #log-icon, #delete-icon {
  color: #B3B5B7;
  padding: 3px;
}

#chat-icon:focus, #note-icon:focus, #resource-icon:focus, #log-icon:focus, #delete-icon:focus {
  outline: none;
}

#chat-button.user-recent {
  background-color: #D03B3B;
}

#chat-button.dashboard-recent {
  background-color: #1F9B0D;
}

#chat-button.chat-closed, #log-button.log-received, #note-button.note-received {
  background-color: #2563AB;
}


#chat-button.user-recent #chat-icon,
#chat-button.dashboard-recent #chat-icon,
#chat-button.chat-closed #chat-icon,
#log-button.log-received #log-icon,
#note-button.note-received #note-icon {
  color: #ffffff;
}

div.status-circle {
  padding: 8px;
  border-radius: 100%;
  margin-left: auto;
  margin-top: 2px;
  margin-right: 5px;
  vertical-align: middle;
  width: 1%;
}

@media only screen and (max-width: 1200px) {
  .action-note, .action-resource, .action-chat, .action-log {
    /*clear: both;*/
    float: left;
    min-height: 65px;
    display: inline-table;
  }

  .action-chat div, .action-note div, .action-log div, .action-resource div {
    clear: both;
    float: none;
  }
}

@media only screen and (max-width: 1000px) {
  .action-chat, .action-log, .action-note, .action-resource {
    /*clear: both;*/
    /*margin-left: 8px;*/
    /*float: none;*/
    float: left;
    min-height: 60px;
    display: inline-table;
  }

  .action-status {
    /*width: 15%;*/
  }
  .rt-td.action-column, .rt-th.rt-resizable-header.-cursor-pointer:last-child {
    width: 20% !important;
  }
}

#delete-button{
  margin-top: 3px;
}
.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.App-content {
  flex: 1;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #354052;
  color: #fff;
  font-size: 12px;
  text-align: center;
  /* z-index: 999; */
}

main {
  padding-bottom: 50px;
}
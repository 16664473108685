::-webkit-scrollbar {
  /*display: none;*/
}

#resource-modal > div {
  width: 100%;
  margin-top: 40%;
  text-align: center;
}
#edits-received {
  color: "#ff0000"
}
#resource-modal-body {
  height: 80%;
  padding-right: 0;
  padding-left: 0;
  margin: 0 auto;
  overflow: hidden;
}

#map {
  position:fixed;
  height: 400px;
  top: 57px;
  width: 100%;
}

#map > div {
  position: static !important;
  width: 100% !important;
  height: 20% !important;
}

#resource-modal-body .list-group {
  position: fixed;
  height: 300px;
  top: 425px;
  overflow: auto;
}

.resource-list .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.resource-display {
  vertical-align: middle;
  margin-left: 5px;
  display: inline-block;
}

.history-times span {
  margin-right: 5px;
}

.elapsed-time {
  float: right;
  vertical-align: middle;
  line-height: 40px;
}

@media only screen and (max-width: 1200px) {
  #resource-modal > div {
    width: 60%;
    margin-top: 7%;
    text-align: center;
  }

  #map {
    position:fixed;
    height: 250px;
    top: 57px;
    width: 100%;
  }

  #map > div {
    position: static !important;
    width: 100% !important;
    height: 20% !important;
  }

  #resource-modal-body .list-group {
    position: fixed;
    max-height: 300px;
    top: 307px;
    overflow: auto;
  }
}

@media only screen and (max-width: 1000px) {
  #resource-modal > div {
    width: 60%;
    margin-top: 2%;
  }
}

@media only screen and (max-height: 1000px) {
  #resource-modal > div {
    width: 60%;
    margin-top: 40px;
  }
}

@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.text-large {
  font-size: 22px
}

.text-medium {
  font-size: 18px;
}

.text-small {
  font-size: 12px;
}

.text-very-small {
  font-size: 8px;
}

#agency-list > a, #login-center-text, #error-jumbotron {
  text-align: center;
}

.text-secondary {
  color: #6c757d !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.p-1 {
  padding: .25rem
}


/* body::-webkit-scrollbar {
  width: 5px;              
}

body::-webkit-scrollbar-track {
  background: white;       
}

body::-webkit-scrollbar-thumb {
  background-color: blue;   
  border-radius: 20px;      
  border: 3px solid orange;  
} */